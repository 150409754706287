import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as helper from '../helpers/helpers';
import { ProfileService } from '../pages/profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  urlBase = environment.endpoint.api;
  private currentUser = new BehaviorSubject<any>(null);

  constructor(
    public http: HttpClient,
    private router: Router,
    private profileService: ProfileService
  ) {
    this.checkToken();
  }

  get currentUser$(): Observable<any> {
    return this.currentUser.asObservable();
  }

  login(params) {
    return this.http.post(`${this.urlBase}/login`, params).toPromise().then(async (resp: any) => {
      /** Almacenamos el Token de seguridad en el localstorage */
      localStorage.setItem('idToken', resp['token']);
      /** Almacenamos el email en el localstorage */
      localStorage.setItem('email', resp['email']);
      /** Almacenamos la fecha de expiración localstorage */
      let today = new Date();
      today.setSeconds(resp['expiresIn']);
      localStorage.setItem('expiresIn', today.getTime().toString());
      
      const infoUser: any = await this.getUser(resp.token)
      this.currentUser.next( infoUser );
      return infoUser
    });
  }

  loginPhoneNumber(params) {
    return this.http.post(`${this.urlBase}/users/phone-verified`, params).toPromise().then(async (resp: any) => {
      /** Almacenamos el Token de seguridad en el localstorage */
      localStorage.setItem('idToken', resp['token']);
      /** Almacenamos el email en el localstorage */
      localStorage.setItem('email', resp['email']);
      /** Almacenamos la fecha de expiración localstorage */
      let today = new Date();
      today.setSeconds(resp['expiresIn']);
      localStorage.setItem('expiresIn', today.getTime().toString());
      
      const infoUser: any = await this.getUser(resp.token)
      this.currentUser.next( infoUser );
      return infoUser
    });
  }

  resetPassword(email: string) {
    return this.http.post(`${this.urlBase}/forgot`, {email}).toPromise();
  }

  logout() {
    return new Promise((resolve, reject)=> {
      localStorage.removeItem('idToken');
      localStorage.removeItem('expiresIn');
      this.currentUser.next( null );
      this.router.navigate(['/auth/login']);
      resolve(true)
    });
  }

  async getUser(idToken) {
    const headers = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Authorization': `Bearer ${idToken}`
    })
    return await this.http.get(`${this.urlBase}/userAuth`, {headers}).toPromise().then(async (infoU: any) => {
      const infoB: any = await this.profileService.getByUser(infoU.id)
      infoB['porcentaje'] = this.profileService.calculateProfile(infoB);
      // console.log(infoB)
      return { ...infoU,  ...infoB}
    })
  }

  checkToken() {
    const token = localStorage.getItem('idToken') || null;
    if (token) {
      const isExpired = helper.isTokenExpired()
      if (isExpired) {
        this.getUser(token).then((resp: any) => {
          /*=============================================
          Validamos respuesta
          =============================================*/
          if(resp && resp !== null) {
            this.currentUser.next( resp );
          } else {
            this.logout()
          }
        }).catch(err => {
          this.logout()
        });
      } else {
        this.logout()
      }
    }
  }

  newPassword(params) {
    return this.http.post(`${this.urlBase}/new-password`, params).toPromise()
  }

  register(params) {
    let request = {
        ...params,
        // photoUrl: null,
        nameCompanyWorks: null,
        workFax: null,
        otherIncome: null,
        socialNetwork: null,
        // department: null,
        accountBankType: null,
        gender: null,
        nationality: null,
        economicActivity: null,
        totalAssets: null,
        dateBirth: null,
        monthlyIncome: null,
        totalLiabilities: null,
        placeBirth: null,
        workPhoneNumber: null,
        accountBankProvider: null,
        accountBankNumber: null,
        managePublicResources: null,
        workAddress: null,
        departmentBirth: null,
        departmentBirthCode: null,
        monthlyExpenses: null,
        // typeDocumento: null,
        cityBirth: null,
        cityBirthCode: null,
        socialNetworkUsername: null,
        // address: null,
        occupation: null,
        vehiclePresents: null,
        workDepartment: null,
        workDepartmentCode: null,
        workCity: null,
        workCityCode: null,
        position: null,
        // numDocumento: null,
        // city: null,
        // cardIdFront: null,
        // cardIdBack: null,
        // checkTermsConditions: null,
        // checkPrivacyPolicy: null,
    }
    return this.profileService.addItem(request)
  }


}
