import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  urlBase = environment.endpoint.api;

  constructor(public http: HttpClient) {}


  getItems() {
    return this.http.get(`${this.urlBase}/beneficiaries`).toPromise();
  }

  getItem(id) {
    return this.http.get(`${this.urlBase}/beneficiaries/${id}`).toPromise();
  }

  addItem(data: any) {
    if (data.id && data.id !== '') {
      return this.updateItem(data)
    } else {
      return this.http.post(`${this.urlBase}/beneficiaries`, data).toPromise();
    }
  }

  updateItem(data: any) {
    return this.http.put(`${this.urlBase}/beneficiaries/${data.id}`, data).toPromise();
  }

  deleteItem(id: string) {
    return this.http.delete(`${this.urlBase}/beneficiaries/${id}`).toPromise();
  }


  getByUser(userId) {
    return this.http.get(`${this.urlBase}/beneficiaries/user/${userId}`).toPromise();
  }

  calculateProfile(obj){
    // console.log(obj.length);
    let count = 0;
    let countSuccess = 0;
    let array = []
    for(let k in obj) {
      count ++;
      if (obj[k] !== '' && obj[k] !== null ) {
        if(k === 'socialNetwork' && obj[k] !== '') {
          array['socialNetwork'] = true;
        }
        if(k === 'accountBankProvider' && obj[k] !== '') {
          array['accountBankProvider'] = true;
        }
        if(k === 'accountBankType' && obj[k] !== '') {
          array['accountBankType'] = true;
        }
        if(k === 'accountBankNumber' && obj[k] !== '') {
          array['accountBankNumber'] = true;
        }
        countSuccess ++;
        // console.log(`${k}:${obj[k]} / Tiene algo => ${count}`)
      } else {
        if(k === 'socialNetwork') {
          array['socialNetwork'] = false;
        }
        if(k === 'accountBankProvider') {
          array['accountBankProvider'] = false;
        }
        if(k === 'accountBankType') {
          array['accountBankType'] = false;
        }
        if(k === 'accountBankNumber') {
          array['accountBankNumber'] = false;
        }
        // console.log(`${k}:${obj[k]} / No Tiene nada => ${count}`)  
      }
    }
    // console.log(countSuccess)
    // console.log(count)
    array['total'] = Math.round(countSuccess * 100 / count)
    return array;
    // console.log(array)
  }

}
