export const isTokenExpired = () => {
  const token = localStorage.getItem('idToken') || null;
  /*=============================================
  Validamos que el idToken sea real
  =============================================*/
  if (token) {
    /*=============================================
    Validamos fecha de expiración
    =============================================*/
    if(localStorage.getItem('expiresIn')) {

      let expiresIn = Number(localStorage.getItem('expiresIn'));
      let expiresDate = new Date();
      expiresDate.setTime(expiresIn);

      if (expiresDate > new Date()) {            
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
    
  } else {
    return false;
  }
}